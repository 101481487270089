<template>
  <div style="width: 140px">
    <p
        style="
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        font-size: 16px;
        border-bottom: 1px solid #f1f1f1;
        background-color: #fff;
      "
    >
      社媒平台
    </p>
    <el-menu :default-active="chosenSite" @select="siteValueChange" v-if="$route.name === 'AmateurRankingList'">
      <el-menu-item
          v-for="item in amateurSiteValue"
          :key="item.value"
          :index="item.value"
          :disabled="isDisabled"
          :style="
          item.value === chosenSite
            ? { borderLeft: '4px solid #51A6F0', paddingLeft: '12px' }
            : { paddingLeft: '16px' }
        "
          :class="size === 'normal' ? 'normal_class' : 'mini_class'"
      >
        <svg-icon
            :class-name="item.value"
            class="icon"
            :class="item.value === chosenSite ? 'icon_active' : 'icon_normal'"
        />
        {{ item.label }}
        <span v-if="showKolNumber">({{ kolNumberData[item.value] }})</span>
      </el-menu-item>
    </el-menu>
    <el-menu :default-active="chosenSite" @select="siteValueChange" v-else>
      <el-menu-item
          v-for="item in sites"
          :key="item.value"
          :index="item.value"
          :disabled="isDisabled"
          :style="
          item.value === chosenSite
            ? { borderLeft: '4px solid #51A6F0', paddingLeft: '12px' }
            : { paddingLeft: '16px' }
        "
          :class="size === 'normal' ? 'normal_class' : 'mini_class'"
      >
        <svg-icon
            :class-name="item.value"
            class="icon"
            :class="item.value === chosenSite ? 'icon_active' : 'icon_normal'"
        />
        {{ item.label }}
        <span v-if="showKolNumber">({{ kolNumberData[item.value] }})</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { defineComponent } from "vue";
// import { useI18n } from "vue-i18n";

export default defineComponent({
  // setup() {
  //   const { t } = useI18n();
  //   return { t };
  // },
  name: "sidebar",
  computed: {
    ...mapState(["isDisabled"]),
    ...mapGetters(["sites", "siteValue", "colSiteValue","amateurSiteValue"]),
    chosenSite() {
      if(this.$route.name === "RankingListV2" || this.$route.name === 'AmateurRankingList'){
        return this.siteValue;
      }else{
        return this.colSiteValue;
      }
    },
  },
  beforeUnmount() {
    this.siteValueChange("douyin");
  },
  props: {
    showKolNumber: {
      type: Boolean,
      default: false,
    },
    kolNumberData: {
      type: Object,
      default() {
        return {
          wechat: 0,
        };
      },
    },
    size: {
      type: String,
      default: "normal",
    },
  },
  methods: {
    ...mapMutations({
      setSiteValue: "SET_SITEVALUE",
      setColSiteValue: "SET_COLSITEVALUE",
      // setIsDisabled: "SET_ISDISABLED",
    }),
    siteValueChange(value) {
      if (this.$route.name === "RankingListV2" || this.$route.name === 'AmateurRankingList') {
        this.setSiteValue(value);
      } else {
        this.setColSiteValue(value);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.el-menu {
  border-right: none;

  .el-menu-item {
    height: 45px;
    line-height: 45px;
    display: flex;
    align-items: center;
  }

  .el-menu-item.is-active {
    background-color: #fcfcfc;
  }

  .normal_class {
    font-size: 14px;
    padding-right: 10px;

    .icon {
      font-size: 16px;
      margin-right: 10px;
    }
  }

  .mini_class {
    font-size: 12px;
    padding-right: 0;

    .icon {
      font-size: 14px;
      margin-right: 6px;
    }
  }
}

.icon_normal {
  fill: #909399;
}

.icon_active {
  fill: #409eff;
}
</style>
